import Vue from "vue";
import moment from "moment";

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  let arr = value.split(" ");
  let capitalized_array = [];
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("lowercase", function (value) {
  if (!value) return "";
  return value.toLowerCase();
});

Vue.filter("bytesToSizes", function (bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
  return Math.round((bytes / Math.pow(1000, i)) * 10, 2) / 10 + " " + sizes[i];
});

Vue.filter("truncate", function (value, limit) {
  if (!isNaN(parseFloat(value)) && isFinite(value)) return value;
  if (value == undefined) return "";
  return value.substring(0, limit);
});

Vue.filter("truncateClean", function (value, limit) {
  if (!isNaN(parseFloat(value)) && isFinite(value)) return value;
  if (value == undefined) return "";

  let substring = value.substring(0, limit);

  if (value.length > limit) substring += "...";
  return substring;
});

Vue.filter("tailing", function (value, tail) {
  return value + tail;
});

Vue.filter("time", function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    if (!is24HrFormat) {
      const time = hours > 12 ? "AM" : "PM";
      hours = hours % 12 || 12;
      return hours + ":" + min + " " + time;
    }
    return hours + ":" + min;
  }
});

Vue.filter("date", function (value, fullDate = false) {
  value = String(value);
  const date = value.slice(8, 10).trim();
  const month = value.slice(4, 7).trim();
  const year = value.slice(11, 15);

  if (!fullDate) return date + " " + month;
  else return date + " " + month + " " + year;
});

Vue.filter("regularDate", function (value) {
  var minDate = moment.utc("0001-01-01");
  if (value == undefined) return moment();
  value = String(value);
  if (isValidTimestamp(value)) {
    return moment.unix(value / 1000).format("DD-MM-YYYY");
  }

  //check if date is low
  if (moment.utc(value).isAfter(minDate)) {
    return moment(value).format("DD-MM-YYYY");
  } else {
    return "Geen datum";
  }
});

Vue.filter("regularDateClean", function (value) {
  var minDate = moment.utc("0001-01-01");
  if (value == undefined) return moment();
  value = String(value);
  if (isValidTimestamp(value)) {
    return moment.unix(value / 1000).format("DD MMMM YYYY");
  }

  //check if date is low
  if (moment.utc(value).isAfter(minDate)) {
    return moment(value).format("DD MMMM YYYY");
  } else {
    return "Geen datum";
  }
});

Vue.filter("regularDateTimeClean", function (value) {
  value = String(value);
  if (isValidTimestamp(value))
    return moment.unix(value / 1000).format("DD MMMM YYYY HH:mm");

  return moment(value).format("DD MMMM YYYY HH:mm");
});

Vue.filter("regularTime", function (value) {
  value = String(value);
  if (isValidTimestamp(value)) return moment.unix(value / 1000).format("HH:mm");

  return moment(value).format("HH:mm");
});

Vue.filter("regularDateTime", function (value) {
  value = String(value);
  if (isValidTimestamp(value))
    return moment.unix(value / 1000).format("DD-MM-YYYY HH:mm");

  return moment(value).format("DD-MM-YYYY HH:mm");
});

Vue.filter("regularMonthYear", function (value) {
  value = String(value);
  if (isValidTimestamp(value))
    return moment.unix(value / 1000).format("MMM YYYY");

  return moment(value).format("MMM YYYY");
});

Vue.filter("regularMonth", function (value) {
  value = String(value);
  if (isValidTimestamp(value))
    return moment.unix(value / 1000).format("MMM YYYY");

  return moment(value).format("MMMM");
});

Vue.filter("regularUnix", function (value) {
  value = String(value);
  if (isValidTimestamp(value)) return moment.unix(value / 1000).valueOf();

  return moment(value).format("DD-MM-YYYY HH:mm");
});

Vue.filter("month", function (val, showYear = true) {
  val = String(val);

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regx.exec(val)[1];
  } else {
    return regx.exec(val)[1] + " " + regx.exec(val)[2];
  }
});

Vue.filter("formatGender", function (val) {
  val = String(val);

  if (val == "0") {
    return "Man";
  }
  if (val == "1") {
    return "Vrouw";
  } else {
    return "Onbekend";
  }
});

Vue.filter("formatRecipient", function (val) {
  val = String(val);

  if (val == "0") {
    return "Planner";
  }
  if (val == "1") {
    return "Docent";
  } else {
    return "Klant";
  }
});

Vue.filter("genderColor", function (val) {
  val = String(val);

  if (val == "0") {
    return "blue lighten-2";
  }
  if (val == "1") {
    return "pink lighten-2";
  } else {
    return "grey lighten-2";
  }
});

Vue.filter("csv", function (value) {
  return value.join(", ");
});

Vue.filter("filter_tags", function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "");
});

Vue.filter("k_formatter", function (num) {
  return num > 999 ? (num / 1000).toFixed(1) + "k" : num;
});

Vue.filter("formatPhone", function (val) {
  if (val == undefined || val == "") return "";
  var parts = val.match(/.{1,2}/g);
  var new_value = parts.join(" "); //returns 123-456-789
  return "+" + new_value;
});

Vue.filter("formatProgression", function (val) {
  if (!isNaN(val)) return ["Load", "Rep", "Set", "Golvend", "RPE"][val];
  else return val;
});

Vue.filter("formatChangeweight", function (val) {
  if (!isNaN(val)) return ["Ja", "Nee"][val];
  else return val;
});

//formatRecurringType
Vue.filter("formatRecurringType", function (val) {
  return [
    "Eenmalig",
    "Dagelijks",
    "Weekelijks",
    "Maandelijks",
    "Jaarlijks",
  ][val];
});

Vue.filter("formatInjuryType", function (val) {
  let array = [
    "Hoofd",
    "linker bovenarm + schouder",
    "Rechter bovenarm + schouder",
    "-",
    "linker onderarm",
    "linker hand",
    "-",
    "Rechter onderarm",
    "rechter hand",
    "Borst regio",
    "Buik regio",
    "linker bovenbeen",
    "linker onderbeen",
    "linker voet",
    "rechter bovenbeen",
    "rechter onderbeen",
    "rechter voet",
    "-",
    "-",
    "-",
  ];
  val = String(val);

  return array[val];
});

Vue.filter("parseChatMessage", function (val) {
  var regex = /\[(.*?)\]/;
  //var regex = /(?<=\[)(.*?)(?=\])/;

  if (val == undefined) return "";
  var matched = regex.exec(val);

  if (matched != null) {
    var newString = val.replace(
      "[" + matched[1] + "]",
      "\n<span class='caption'>Klik onderaan dit bericht om naar de training te gaan</span>"
    );
    return newString;
  } else {
    return val;
  }
});

Vue.filter("userObjectToFullname", function (obj) {
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    if ("firstname" in obj && "lastname" in obj && "insertion" in obj) {
      let fullname =
        obj.insertion == null
          ? obj.firstname + " " + obj.lastname
          : obj.firstname + " " + obj.insertion + " " + obj.lastname;
      return fullname;
    } else {
      return "";
    }
  } else {
    return obj;
  }
});

Vue.filter("locationToReadable", function (obj) {
  if (typeof obj === "object" && obj !== null) {
    let pre = "incompany";
    let after = "";

    if (obj.psLocationId != null) {
      pre = "ps";
      after = obj.locationName;
    } else {
      if (obj.addToPs != undefined && obj.addToPs == true) {
        pre = "nieuw";
      }
      after = obj.address + " " + obj.city;
    }

    return `(${pre}) ${after}`;
  } else {
    return "geen locatie";
  }
});

Vue.filter("formatBoolToText", function (val, invert) {
  var valBool = val === "true";
  if (invert) valBool = !valBool;
  return valBool ? "Ja" : "Nee";
});

Vue.filter("validate_date_format_dutch", function (value) {
  //check valid using regex
  const regx = new RegExp(
    "^([1-2][0-9]|3[0-1]|0?[1-9])[-]([1][0-2]|0?[1-9])[-]([0-9]{4})$"
  );

  // Check valid date using moment for both formats
  const dateFormats = ["DD-MM-YYYY", "D-M-YYYY"];
  const isValidFormat = dateFormats.some((format) =>
    moment(value, format, true).isValid()
  );

  return regx.exec(value) && isValidFormat;
});

Vue.filter("validate_time_format", function (value) {
  const regx = new RegExp("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$");
  return regx.exec(value);
});

function isValidTimestamp(_timestamp) {
  return moment(_timestamp, "X", true).isValid();
}
