export default {
  request: function (req, token) {
    if (req.url === this.options.refreshData.url) {
      //localstorage for app
      var storage = window.localStorage;
      var oldStorage = this.token(this.options.refreshTokenName);
      var value = storage.getItem(this.options.refreshTokenName);

      var realToken =
        value == undefined || value == "" || value == null ? oldStorage : value;

      req.data = {
        refreshToken: realToken,
        accessToken: token,
      };
    }
    this.drivers.http.setHeaders.call(this, req, {
      Authorization: "Bearer " + token,
    });
  },

  response: function (res) {
    var token = res.data.token || res.data.accessToken;
    const resData = res.data || {};
    this.token(this.options.refreshTokenName, resData.refreshToken);

    //localstorage for app
    var storage = window.localStorage;

    // console.log(res, this.options, storage, window.location.href);

    if (token) {
      storage.setItem(this.options.refreshTokenName, resData.refreshToken);
      storage.setItem(this.options.tokenDefaultKey, token);

      token = token.split(/Bearer:?\s?/i);

      return token[token.length > 1 ? 1 : 0].trim();
    }
  },
};
