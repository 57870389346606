import { render, staticRenderFns } from "./viewOption.vue?vue&type=template&id=06d8a0f4"
import script from "./viewOption.vue?vue&type=script&lang=js"
export * from "./viewOption.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports