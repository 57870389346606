<template>
  <div>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <div>
      <v-row>
        <v-col cols="12" sm="12" class="pa-0 pa-md-1">
          <BaseCard :heading="currentName">
            <v-row>
              <v-col>
                <v-btn
                  rounded
                  color="primary"
                  outlined
                  @click="navigate('Courses')"
                >
                  <v-icon left> mdi-arrow-left </v-icon>
                  Terug
                </v-btn>
              </v-col>

              <v-spacer> </v-spacer>
              <v-col class="text-right">
                <div class="title">Inzage</div>
              </v-col>
            </v-row>
            <v-speed-dial
              v-model="fab"
              top
              right
              direction="bottom"
              transition="slide-y-transition"
            >
              <template v-slot:activator>
                <v-btn v-model="fab" color="secondary" class="mb-6" fab dark>
                  <v-icon v-if="fab"> mdi-close </v-icon>
                  <v-icon v-else> mdi-cog </v-icon>
                </v-btn>
              </template>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    small
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    @click="allSwitchesDialog = true"
                  >
                    <v-icon>mdi-check-all</v-icon>
                  </v-btn>
                </template>
                <span>Alle switches op afgerond zetten</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    @click="cancelDialog = true"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Cursus annuleren</span>
              </v-tooltip>
            </v-speed-dial>
            <!-- <v-fab-transition>
              <v-btn
                v-show="!hidden"
                color="secondary"
                class="mb-6"
                fab
                dark
                absolute
                top
                right
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </v-fab-transition> -->

            <div class="mx-auto w-3/4">
              <div class="grid grid-cols-3 gap-4 place-items-center">
                <!-- <pre>{{ this.switchesByCourse }}</pre> -->
                <v-card
                  v-show="!isLoaded"
                  flat
                  class="w-full h-full"
                  v-for="n in 4"
                  :key="n"
                >
                  <v-skeleton-loader
                    type="article, actions"
                  ></v-skeleton-loader>
                </v-card>
                <div
                  v-for="item in this.switchesByCourse"
                  :key="item.courseSwitchId"
                  v-show="isLoaded"
                >
                  <v-card color="#385F73" dark v-if="item != undefined">
                    <v-card-title class="text-h5">
                      {{ item.switchName }}
                    </v-card-title>
                    <v-card-subtitle
                      >Vul een waarde in het veld of klik op het schuifje om aan
                      te geven dat dit voltooid is voor deze
                      cursus.</v-card-subtitle
                    >
                    <v-card-text>
                      <!-- <pre>{{ item }}</pre> -->
                      <v-row>
                        <v-col cols="6">
                          <div v-if="item.value == 'false'">
                            <v-switch
                              v-model="item.value"
                              color="primary"
                            ></v-switch>
                          </div>
                          <div v-else>
                            <div v-if="item.type == 'number'">
                              <v-text-field
                                type="number"
                                class="inputNr"
                                v-model="item.value"
                                :label="item.name"
                              >
                              </v-text-field>
                            </div>
                            <div v-if="item.type == 'text'">
                              <v-text-field
                                type="text"
                                class="inputNr"
                                v-model="item.value"
                                :label="item.name"
                              >
                              </v-text-field>
                            </div>
                            <div v-else>
                              <v-switch
                                v-model="item.value"
                                color="primary"
                              ></v-switch>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6" class="text-right">
                          <v-btn
                            v-if="item.courseSwitchId != null"
                            class="ma-2 align-right"
                            @click="DeleteSwitch(item)"
                            outlined
                            color="white"
                          >
                            Verwijderen
                          </v-btn>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
                        <v-divider></v-divider>
                      </v-row> -->
                    </v-card-text>
                    <v-card-actions class="text-right">
                      <v-spacer></v-spacer
                      ><v-icon
                        :color="item.type == 'multiple' ? '' : 'transparent'"
                        @click="show = !show"
                        >{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon
                      >
                    </v-card-actions>
                    <v-expand-transition v-if="item.type == 'multiple'">
                      <div v-show="show">
                        <v-divider></v-divider>

                        <v-card-text>
                          <p>Koppel extra informatie aan deze switch</p>
                          <v-row
                            align="center"
                            class="mt-0"
                            v-for="data in item.courseSwitchDatas"
                            :key="data.id"
                            v-show="data.deleted != true"
                          >
                            <v-checkbox
                              v-model="data.keyValue"
                              @change="updateCheckbox(item)"
                              hide-details
                              class="shrink mr-2 ml-8 mt-0"
                            ></v-checkbox>
                            <v-text-field
                              type="text"
                              class="inputNr mr-8"
                              v-model="data.value"
                              :label="data.name"
                              append-outer-icon="mdi-close"
                              @click:append-outer="removeExtraData(item, data)"
                            ></v-text-field>
                          </v-row>
                          <v-btn
                            class="ma-1"
                            color="white"
                            small
                            plain
                            @click="addNewCourseSwitchData(item)"
                          >
                            Nieuwe toevoegen<v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </div>
                <!-- <div v-for="item in courseswitch" :key="item.id">
                  <v-card color="#385F73" dark v-if="item != undefined">
                    <v-card-title class="text-h5">
                      {{ item.switch.name }}
                    </v-card-title>
                    <v-card-subtitle
                      >Vul een waarde in het veld of klik op het schuifje om aan
                      te geven dat dit voltooid is voor deze
                      cursus.</v-card-subtitle
                    >
                    <v-card-actions>
                      <div v-if="item.value == 'false'">
                        <v-switch
                          v-model="item.switch.value"
                          color="primary"
                          value="null"
                        ></v-switch>
                      </div>
                      <div v-else>
                        <div v-if="item.switch.type == 'number'">
                          <v-text-field
                            type="number"
                            class="inputNr"
                            v-model="item.value"
                            :label="item.name"
                          ></v-text-field>
                        </div>
                        <div v-else>
                          <v-switch
                            v-model="item.switch.value"
                            color="primary"
                            :value="item.value"
                          ></v-switch>
                        </div>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2 align-right"
                        @click="DeleteSwitch(item)"
                        outlined
                        color="white"
                      >
                        Verwijderen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div> -->
                <v-card v-show="!isLoaded" flat class="w-full h-full">
                  <v-skeleton-loader type="article, button"></v-skeleton-loader>
                </v-card>
                <v-card
                  class="w-full h-full"
                  color="#385F73"
                  dark
                  v-show="isLoaded"
                >
                  <v-card-title class="text-h5">
                    Switch toevoegen?
                  </v-card-title>
                  <v-card-subtitle
                    >Moet er wat extra geregeld worden? Maak dan nog een switch
                    aan.</v-card-subtitle
                  >
                  <v-card-actions>
                    <v-btn
                      class="ma-2"
                      @click="courseSwitchToggle"
                      outlined
                      color="white"
                    >
                      Toevoegen
                    </v-btn>
                    <add-course-switch
                      @courseSwitchToggle="dialog = !dialog"
                      :active="dialog"
                    >
                    </add-course-switch>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
            <div class="flex justify-center">
              <v-skeleton-loader
                v-if="!isLoaded"
                class="m-4 p-4 w-1/4"
                type="heading"
              ></v-skeleton-loader>
              <v-btn v-else color="primary m-4 p-4 w-1/4" @click="saveSwitch()"
                >Opslaan</v-btn
              >
            </div>
            <!-- <pre>{{ switchesByCourse }}</pre> -->
          </BaseCard>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="allSwitchesDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Switches op afgerond zetten</span>
        </v-card-title>
        <v-card-text>
          <p>
            Weet je zeker dat je alle switches op afgerond wilt zetten? Alle
            tekstvelden die <b>niet</b> zijn ingevuld krijgen de waarde
            <b>vuller</b>.
          </p>
          <v-checkbox
            v-model="FinishInPs"
            label="Cursus in planningssysteem op afgerond zetten"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="allSwitchesDialog = false"> Annuleren </v-btn>
          <v-btn
            color="success darken-1"
            text
            @click="setAllSwitchesFinished()"
          >
            Akkoord
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelDialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Annuleer cursus</span>
        </v-card-title>
        <v-card-text>
          <p>
            Weet je zeker dat je deze cursus wilt annuleren? De docent zal op de
            hoogte worden gesteld door middel van een mailjte.
          </p>
          <!-- <small>*Verplichte velden</small> -->
          <v-radio-group v-model="radioCancelCourse" mandatory>
            <v-radio label="Verwijder de cursus volledig" :value="0"></v-radio>
            <v-radio
              label="Cursus inactief stellen door de docenten te verwijderen en aantekening toe te voegen."
              :value="1"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelDialog = false"> Annuleren </v-btn>
          <v-btn color="success darken-1" text @click="cancelCourse()">
            Akkoord
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}

.v-speed-dial--top {
  top: -30px !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import addCourseSwitch from "../modals/AddCourseSwitchDialog.vue";
export default {
  data() {
    return {
      page: {
        title: "Cursus inzage",
      },
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          to: "/home/opties",
        },
        {
          text: "Tabs",
          disabled: false,
          to: "/home/opties",
        },
        {
          text: "Cursussen",
          disabled: false,
          to: "/home/cursussen",
        },
        {
          text: "Inzage",
          disabled: true,
        },
      ],
      tabs: null,
      dialog: false,
      isLoaded: false,
      item: {},
      FinishInPs: false,
      extra: [],
      courseOptions: [],
      courseheaders: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Plaatsen", value: "places" },
        { text: "Opmerking", value: "remark" },
      ],
      fab: null,
      allSwitchesDialog: false,
      cancelDialog: false,
      show: false,
      radioCancelCourse: null,
    };
  },
  components: {
    addCourseSwitch,
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters({
      courseswitch: "courses/getCourseSwitches",
      courses: "courses/getCourses",
      course: "courses/getCourse",
      switchesByCourse: "courses/getSwitchesByCourse",
      depSwitch: "courses/getDepSwitches",
    }),
    courseId() {
      return this.$route.params.id;
    },
    currentName() {
      let obj = this.course;
      // return obj;
      if (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      ) {
        return "";
      } else {
        return obj.trainingName;
      }
    },
  },
  methods: {
    initialize() {
      this.fetchCourse();
    },
    navigate(to) {
      this.$router.push({ name: to });
    },
    fetchCourse() {
      let payload = this.courseId;
      this.$store
        .dispatch("courses/fetchCourse", payload)
        .then(() => {
          this.fetchDepartmentSwitches();
          this.fetchCourseSwitch();
          this.fetchSwitchesByCourse();
          // this.fetchCourseSwitches();
        })
        .catch(function () {});
    },
    fetchDepartmentSwitches() {
      var departmentId = "";
      if (this.course.departmentId == 91) {
        departmentId = 2;
      } else {
        departmentId = 1;
      }

      var courseId = this.courseId;
      var obj = {
        departmentId: departmentId,
        courseId: courseId,
      };

      let payload = obj;
      this.$store.dispatch("courses/fetchDepartmentSwitch", payload);
    },
    fetchCourseSwitch() {
      var courseId = this.courseId;
      let payload = courseId;
      this.$store.dispatch("courses/fetchCourseSwitch", payload);
    },
    fetchSwitchesByCourse() {
      var courseId = this.courseId;
      let payload = {
        courseId: courseId,
      };
      this.$store
        .dispatch("courses/fetchSwitchesByCourse", payload)
        .then(() => {
          this.isLoaded = true;
        });
    },
    // fetchCourseSwitches() {
    //   var courseId = this.courseId;
    //   let payload = courseId;
    //   this.$store.dispatch("courses/fetchCourseSwitches");
    // },
    DeleteSwitch(obj) {
      let payload = obj;
      this.$store
        .dispatch("courses/deleteCourseSwitch", payload)
        .then(() => {
          this.$store.dispatch("addMessage", {
            message: "Switch is verwijderd",
            bottom: true,
            top: false,
            right: true,
            color: "green",
            transition: "fab-transition",
            timeout: 3000,
          });
        })
        .catch(function () {
          this.$store.dispatch("addMessage", {
            message: "Er ging wat fout.",
            bottom: true,
            top: false,
            right: true,
            color: "error",
            transition: "fab-transition",
            timeout: 3000,
          });
        });
    },
    setAllSwitchesFinished() {
      let app = this;
      let payload = {
        id: this.courseId,
        finish: this.FinishInPs,
      };
      this.allSwitchesDialog = false;
      this.$store
        .dispatch("courses/finishAllCourseSwitches", payload)
        .then(() => {
          app.$store.dispatch("addMessage", {
            message: "Switches zijn afgerond",
            bottom: true,
            top: false,
            right: true,
            color: "green",
            transition: "fab-transition",
            timeout: 3000,
          });
        })
        .catch(function () {
          app.$store.dispatch("addMessage", {
            message: "Er ging wat fout.",
            bottom: true,
            top: false,
            right: true,
            color: "error",
            transition: "fab-transition",
            timeout: 3000,
          });
        });
    },
    cancelCourse() {
      let app = this;
      let payload = {
        id: this.courseId,
        cancelType: this.radioCancelCourse,
      };
      this.cancelDialog = false;
      this.$store
        .dispatch("courses/cancelCourse", payload)
        .then(() => {
          app.$store.dispatch("addMessage", {
            message: "Cursus is geannuleerd.",
            bottom: true,
            top: false,
            right: true,
            color: "green",
            transition: "fab-transition",
            timeout: 3000,
          });
        })
        .catch(function () {
          app.$store.dispatch("addMessage", {
            message: "Er ging wat fout.",
            bottom: true,
            top: false,
            right: true,
            color: "error",
            transition: "fab-transition",
            timeout: 3000,
          });
        });
    },
    addNewCourseSwitchData(item) {
      let switchItem = this.switchesByCourse.find(
        (x) => x.courseSwitchId == item.courseSwitchId
      );
      switchItem.courseSwitchDatas.push({
        id: null,
        uuid: this.uuidv4(),
        courseSwitchId: item.courseSwitchId,
        keyValue: false,
        value: "",
      });
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    updateCheckbox(item) {
      if (item.courseSwitchDatas != null) {
        let isValid = true;
        item.courseSwitchDatas.forEach((element) => {
          if (!element.keyValue) isValid = false;
        });

        if (isValid) item.value = true;
        else item.value = false;
      }
    },
    removeExtraData(item, data) {
      if (data.id != null) {
        //server delete
        let index = item.courseSwitchDatas.findIndex(
          (element) => element.id == data.id
        );

        if (index >= 0) item.courseSwitchDatas[index].deleted = true;
      } else {
        //regular delete
        let index = item.courseSwitchDatas.findIndex(
          (element) => element.uuid == data.uuid
        );
        if (index >= 0) item.courseSwitchDatas.splice(index, 1);
      }
    },
    courseSwitchToggle() {
      this.dialog = !this.dialog;
    },
    saveSwitch() {
      let app = this;
      this.$store.dispatch("toggleLoader");
      var switcharray = [];

      for (var i = 0; i < this.switchesByCourse.length; i++) {
        if (this.switchesByCourse.length > i) {
          let type = this.switchesByCourse[i].type;

          var check = this.switchesByCourse[i].value;
          if (check == undefined) {
            check = "false";
          }
          if (check == true) {
            check = "true";
          }
          if (check == "") {
            check = "";
          }

          //check for multiple
          if (type == "multiple") {
            let multipleDatas = this.switchesByCourse[i].courseSwitchDatas;
            multipleDatas.forEach((data) => {
              var key = data.keyValue;
              if (key == undefined) {
                key = "false";
              }
              if (key == true) {
                key = "true";
              }
              if (key == "") {
                key = "";
              }

              //key.deleted = false;

              data.keyValue = key;
            });
          }

          switcharray.push({
            id: this.switchesByCourse[i].id,
            courseswitchId: this.switchesByCourse[i].courseSwitchId,
            switchId: this.switchesByCourse[i].switchId,
            departmentId: this.switchesByCourse[i].departmentId,
            courseId: this.courseId,
            value: check,
            courseSwitchDatas: this.switchesByCourse[i].courseSwitchDatas,
          });
        }
      }
      let payload = switcharray;
      this.$store
        .dispatch("courses/editCourseSwitch", payload)
        .then(() => {
          app.$store.dispatch("toggleLoader");
          app.$store.dispatch("addMessage", {
            message: "Cursus is opgeslagen!",
            bottom: true,
            top: false,
            right: true,
            color: "green",
            transition: "fab-transition",
            timeout: 3000,
          });
        })
        .catch(function () {
          app.$store.dispatch("toggleLoader");
          app.$store.dispatch("addMessage", {
            message: "Er ging wat fout.",
            bottom: true,
            top: false,
            right: true,
            color: "error",
            transition: "fab-transition",
            timeout: 3000,
          });
        });
    },
  },
};
</script>
