/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  departments: [],
  error: null,
  currentEnvironment: "",
  serverEnvironment: "",
};

// getters
const getters = {
  getDepartments: (state) => {
    return state.departments;
  },
  getIsDevelopment: (state) => {
    return state.currentEnvironment == "development";
  },
  getServerEnvironment: (state) => {
    return state.serverEnvironment;
  },
};

// actions
const actions = {
  sendTestNotification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Notifications/test/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_NOTIFICATIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendError({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Tools/sendError")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("SEND_ERROR", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setCurrentEnvironment({ commit }) {
    let currentEnv = process.env.NODE_ENV;
    commit("SET_CURRENT_ENVIRONMENT", currentEnv);
  },
  fetchServerEnvironment({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Tools/environment")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          console.log(res.data);
          commit("FETCH_SERVER_ENVIRONMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_NOTIFICATIONS(state, data) {
    state.departments = data;
  },
  SEND_ERROR(state, data) {
    state.error = data;
  },
  SET_CURRENT_ENVIRONMENT(state, data) {
    state.currentEnvironment = data;
  },
  FETCH_SERVER_ENVIRONMENT(state, data) {
    state.serverEnvironment = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
