/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  courseRequests: [],
  currentCourseRequest: {},
  editableCourseRequest: {},
  deleted: [],
};

// getters
const getters = {
  getCourseRequests: (state) => {
    return state.courseRequests;
  },
  getCurrentCourseRequest: (state) => {
    return state.currentCourseRequest;
  },
  getEditableCourseRequest: (state) => {
    return state.editableCourseRequest;
  },
  getDeletedCourseRequests: (state) => {
    return state.deleted;
  },
};

// actions
const actions = {
  //get all new registration
  fetchCoursesRequests({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequests")
        .then((res) => {
          setTimeout(() => resolve(res.data), 50);
          commit("FETCH_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editCoursesRequests({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/CourseRequests/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addCoursesRequests({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseRequests", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("ADD_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/CourseRequests/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  hardDeleteCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/CourseRequests/hard/" + payload.courseRequestId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("HARD_DELETE_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  hideCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/CourseRequests/hide/" + payload.courseRequestId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("HARD_DELETE_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequests/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("GET_COURSEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getEditableCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequests/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("GET_EDITABLE_COURSEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDeletedCoursesRequests({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequests/deleted")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_DELETEDCOURSEREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_COURSESREQUESTS(state, data) {
    state.courseRequests = data;
  },
  FETCH_DELETEDCOURSEREQUESTS(state, data) {
    state.deleted = data;
  },
  EDIT_COURSESREQUESTS(state, data) {
    state.courseRequests = [
      ...state.courseRequests.map((item) =>
        item.id !== data.id ? item : { ...item, ...data }
      ),
    ];
  },
  ADD_COURSESREQUESTS(state, data) {
    state.courseRequests.push(data);
  },
  GET_COURSEREQUEST(state, data) {
    state.currentCourseRequest = data;
  },
  GET_EDITABLE_COURSEREQUEST(state, data) {
    state.editableCourseRequest = data;
  },
  DELETE_COURSESREQUESTS(state, data) {
    let index = state.courseRequests.findIndex(
      (element) => element.id == data.id
    );
    state.courseRequests.splice(index, 1);
  },
  HARD_DELETE_COURSESREQUESTS(state, data) {
    let index = state.courseRequests.findIndex(
      (element) => element.id == data.id
    );
    state.courseRequests.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
