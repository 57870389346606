/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  courses: [],
  course: {},
  courseRequests: [],
  switches: [],
  courseSwitches: [],
  depSwitches: [],
  switchesByCourse: [],
  trainings: [],
  currentCourseRequest: {},
  editableCourseRequest: {},
  deleted: [],
  contacts: [],
};

// getters
const getters = {
  getCourses: (state) => {
    return state.courses;
  },
  getCourse: (state) => {
    return state.course;
  },
  getSwitches: (state) => {
    return state.switches;
  },
  getCourseRequests: (state) => {
    return state.courseRequests;
  },
  getCourseSwitches: (state) => {
    return state.courseSwitches;
  },
  getSwitchesByCourse: (state) => {
    return state.switchesByCourse;
  },
  getDepSwitches: (state) => {
    return state.depSwitches;
  },
  getDeletedCourseRequests: (state) => {
    return state.deleted;
  },
  getCurrentCourseRequest: (state) => {
    return state.currentCourseRequest;
  },
  getEditableCourseRequest: (state) => {
    return state.editableCourseRequest;
  },
  getTrainings: (state) => {
    return state.trainings;
  },
  getContacts: (state) => {
    return state.contacts;
  },
};

// actions
const actions = {
  //get all new registration
  fetchCourses({ commit }, all) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Courses/all/0/" + all)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_COURSES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchSwitches({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Switches")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_SWITCHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchSwitchesByMultipleCourses({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Switches/multiple", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_SWITCHES_BY_COURSE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchSwitchesByCourse({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Switches/" + payload.courseId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_SWITCHES_BY_COURSE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourse({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Courses/" + payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_COURSE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoursesRequests({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequests")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseSwitch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseSwitches/" + payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_COURSESWITCHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseSwitches({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseSwitches/")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_COURSESWITCHES", res.data);
        })
        .catch((err) => {
          commit("FETCH_COURSESWITCHES", []);
          reject(err);
        });
    });
  },
  addCourseSwitch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseSwitches/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("ADD_COURSESWITCHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addMultipleCourseSwitches({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseSwitches/multiple", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("ADD_COURSESWITCHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDepartmentSwitch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/DepartmentSwitches/" +
            payload.departmentId +
            "/" +
            payload.courseId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_DEPSWITCHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editCourseSwitch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/CourseSwitches", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_COURSESWITCH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editMultipleCourseSwitches({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/CourseSwitches/multiple", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_COURSESWITCH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDeletedCoursesRequests({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequests/deleted")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_DELETEDCOURSEREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editCoursesRequests({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/CourseRequests/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addCoursesRequests({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseRequests", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("ADD_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/CourseRequests/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_COURSESREQUESTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCourse({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/courses/" + payload.courseId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_COURSE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCourseSwitch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/CourseSwitches/" + payload.courseSwitchId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_COURSESWITCH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteMultipleCourseSwitches({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/CourseSwitches/multiple/" + payload.switch.switchId, {
          data: payload,
        })
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_MULTIPLE_COURSESWITCH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTrainings({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_TRAININGS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequests/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("GET_COURSEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getEditableCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequests/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("GET_EDITABLE_COURSEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  connectTeacherToCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "api/CourseRequests/connectteacher/" + payload.courseRequestId,
          payload
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("GET_COURSEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  disconnectTeacherFromCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "api/CourseRequests/disconnectteacher/" + payload.courseRequestId,
          payload
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("GET_COURSEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  finishAllCourseSwitches({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/switches/finish/" + payload.id + "/" + payload.finish)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FINISH_COURSESWITCHES", payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  cancelCourse({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/courses/cancel/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CANCEL_COURSE", payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //contacts
  fetchContactsByCompany({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Contacts/company/" + payload.companyId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_CONTACTS", res.data);
        })
        .catch((err) => {
          commit("FETCH_CONTACTS", []);
          reject(err);
        });
    });
  },

  fillFinishedCourseSwitches({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Switches/addFinished/")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_CONTACTS", []);
        })
        .catch((err) => {
          commit("FETCH_CONTACTS", []);
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_SWITCHES_BY_COURSE(state, data) {
    data.forEach((element) => {
      if (element.type == "number" || element.type == "text") {
        if (element.value.length <= 0) element.value = "";
      } else if (element.type == "multiple") {
        element.courseSwitchDatas.forEach((data) => {
          data.keyValue = data.keyValue === "true";
          data.deleted = false;
        });
      } else {
        element.value = element.value === "true";
      }
    });

    state.switchesByCourse = data;
  },
  FETCH_COURSE(state, data) {
    state.course = data;
  },
  FETCH_COURSES(state, data) {
    state.courses = data;
  },
  FETCH_SWITCHES(state, data) {
    state.switches = data;
  },
  FETCH_COURSESREQUESTS(state, data) {
    state.courseRequests = data;
  },
  FETCH_COURSESWITCHES(state, data) {
    state.courseSwitches = data;
  },
  ADD_COURSESWITCHES(state, data) {
    let newarray = reformatStringToBoolArray(
      data.concat(state.switchesByCourse)
    );
    newarray.sort((a, b) => (a.position > b.position ? 1 : -1));

    newarray.forEach((element) => {
      if (element.type == "multiple") {
        element.courseSwitchDatas = [];
      }
    });

    state.switchesByCourse = newarray;
  },
  FETCH_DEPSWITCHES(state, data) {
    state.depSwitches = data;
  },
  FETCH_DELETEDCOURSEREQUESTS(state, data) {
    state.deleted = data;
  },
  FETCH_TRAININGS(state, data) {
    state.trainings = data;
  },
  EDIT_COURSESREQUESTS(state, data) {
    state.courseRequests = [
      ...state.courseRequests.filter((element) => element.id !== data.id),
      data,
    ];
  },
  EDIT_COURSESWITCH(state, data) {
    console.log(data);
    // state.courseSwitches = [
    //   ...state.courseSwitches.filter((element) => element.id !== data.id),
    //   data,
    // ];
  },
  ADD_COURSESREQUESTS(state, data) {
    state.courseRequests.push(data);
  },
  GET_COURSEREQUEST(state, data) {
    state.currentCourseRequest = data;
  },
  GET_EDITABLE_COURSEREQUEST(state, data) {
    state.editableCourseRequest = data;
  },
  DELETE_COURSESREQUESTS(state, data) {
    // state.courseRequests = [
    //   ...state.courseRequests.findIndex((element) => element.id == data.id),
    //   state.courseRequests.splice(data, 1)
    // ];
    let index = state.courseRequests.findIndex(
      (element) => element.id == data.id
    );
    state.courseRequests.splice(index, 1);
  },
  DELETE_COURSE(state, data) {
    // state.courseRequests = [
    //   ...state.courseRequests.findIndex((element) => element.id == data.id),
    //   state.courseRequests.splice(data, 1)
    // ];
    let index = state.courses.findIndex((element) => element.id == data.id);
    state.courses.splice(index, 1);
  },
  DELETE_COURSESWITCH(state, data) {
    let index = state.switchesByCourse.findIndex(
      (element) => element.courseSwitchId == data.id
    );

    state.switchesByCourse.splice(index, 1);
  },
  DELETE_MULTIPLE_COURSESWITCH(state, data) {
    let index = state.switchesByCourse.findIndex(
      (element) => element.switchId == data.switchId
    );

    state.switchesByCourse.splice(index, 1);
  },
  FETCH_CONTACTS(state, data) {
    state.contacts = data;
  },
  FINISH_COURSESWITCHES(state, data) {
    if (data) {
      let arrayText = state.switchesByCourse.filter((x) => x.type == "text");
      let arrayBool = state.switchesByCourse.filter((x) => x.type != "text");

      arrayBool.forEach((element) => {
        let obj = element;
        obj.value = true;
        element = obj;
      });

      arrayText.forEach((element) => {
        let obj = element;
        if (obj.value.length <= 0) obj.value = "vuller";
        element = obj;
      });

      let newarray = arrayBool.concat(arrayText);
      newarray.sort((a, b) => (a.position > b.position ? 1 : -1));

      state.switchesByCourse = newarray;
    }
  },
};

function reformatStringToBoolArray(data) {
  // console.log("data", data);
  // console.log("state.switchesByCourse", state.switchesByCourse);
  // data.forEach(element => {

  //   if (element.type == "number" || element.type == "text"){
  //     if(element.value.length <= 0)
  //     element.value = "";
  //   }
  //   else
  //     element.value = (element.value === 'true');
  // });

  return data;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
