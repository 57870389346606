/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";
import Vuetify from "../../plugins/vuetify";

// initial state
const state = {
  theme: {},
  themePartners: [],
};

// getters
const getters = {
  getTheme: (state) => {
    return state.theme;
  },
  getThemePartners: (state) => {
    return state.themePartners;
  },
};

// actions
const actions = {
  fetchTheme({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Theme/" + payload.courseRequestId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("SET_THEME", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchThemeRegular({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Theme")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("SET_THEME", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchThemePartners({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Theme/partners")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("SET_THEME_PARTNERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  SET_THEME(state, data) {
    state.theme = data;
    //set vuetity instanced theme
    Vuetify.framework.theme.dark = data.darkMode;

    if (data.darkMode) {
      Vuetify.framework.theme.themes.dark.primary = data.primaryColor;
      Vuetify.framework.theme.themes.dark.secondary = data.secondaryColor;
      Vuetify.framework.theme.themes.dark.info = data.secondaryColor;
      Vuetify.framework.theme.themes.dark.success = data.success;
      Vuetify.framework.theme.themes.dark.accent = data.accent;
      Vuetify.framework.theme.themes.dark.default = data.default;
      Vuetify.framework.theme.themes.dark.complement = data.complement;
    } else {
      Vuetify.framework.theme.themes.light.primary = data.primaryColor;
      Vuetify.framework.theme.themes.light.secondary = data.secondaryColor;
      Vuetify.framework.theme.themes.light.info = data.secondaryColor;
      Vuetify.framework.theme.themes.light.success = data.success;
      Vuetify.framework.theme.themes.light.accent = data.accent;
      Vuetify.framework.theme.themes.light.default = data.default;
      Vuetify.framework.theme.themes.light.complement = data.complement;
    }
  },
  SET_THEME_PARTNERS(state, data) {
    state.themePartners = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
