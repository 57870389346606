/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  specification: [],
  specificationTotal: [],
  courseRequestSpecifications: [],
  availableSpecifications: [],
};

// getters
const getters = {
  GetDepartmentSpecification: (state) => {
    return state.specification;
  },
  GetTotalSpecifications: (state) => {
    return state.specificationTotal;
  },
  GetCourseRequestSpecifications: (state) => {
    return state.courseRequestSpecifications;
  },
  GetAvailableSpecifications: (state) => {
    return state.availableSpecifications;
  },
};

// actions
const actions = {
  //get all new registration
  fetchDepartmentSpecification({ commit }, payload) {
    let departmentId = payload.departmentId;
    if (departmentId == 1) {
      departmentId = 4;
    } else if (departmentId == 2) {
      departmentId = 91;
    } else if (departmentId == 3) {
      departmentId = 4687;
    } else if (departmentId == 4) {
      departmentId = 4029;
    } else if (departmentId == 5) {
      departmentId = 68966;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/specifications/planningssysteem/" +
            departmentId +
            "/" +
            payload.year +
            "/" +
            payload.month
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_SPECIFICATION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAllSpecifications({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/specifications/planningssysteem")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_SPECIFICATIONTOTAL", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseRequestSpecifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/specifications/courserequest/" + payload.courseRequestId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COURSE_REQUEST_SPECIFICATION", res.data);
        })
        .catch((err) => {
          commit("FETCH_COURSE_REQUEST_SPECIFICATION", []);
          reject(err);
        });
    });
  },
  fetchAvailableSpecifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/specifications/availableSpecifications", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_AVAILABLE_SPECIFICATIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addToCurrentSpecifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/specifications/addSpecification", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_TO_CURRENT_SPECIFICATIONS", payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  removeToCurrentSpecifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/specifications/removeSpecification", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("REMOVE_TO_CURRENT_SPECIFICATIONS", payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editCurrentSpecifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/specifications/editSpecification", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_CURRENT_SPECIFICATIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_SPECIFICATION(state, data) {
    state.specification = data;
  },
  FETCH_SPECIFICATIONTOTAL(state, data) {
    state.specificationTotal = data;
  },
  FETCH_COURSE_REQUEST_SPECIFICATION(state, data) {
    state.courseRequestSpecifications = data;
  },
  FETCH_AVAILABLE_SPECIFICATIONS(state, data) {
    state.availableSpecifications = data;
  },
  ADD_TO_CURRENT_SPECIFICATIONS(state, data) {
    data.totalPrice = data.price;
    data.amount = 1;
    //Add to current
    state.courseRequestSpecifications.push(data);

    //remove from old
    let index = state.availableSpecifications.findIndex(
      (element) => element.specificationId == data.specificationId
    );
    state.availableSpecifications.splice(index, 1);
  },
  REMOVE_TO_CURRENT_SPECIFICATIONS(state, data) {
    //Add to current
    state.availableSpecifications.push(data);

    //remove from old
    let index = state.courseRequestSpecifications.findIndex(
      (element) => element.specificationId == data.specificationId
    );
    state.courseRequestSpecifications.splice(index, 1);
  },
  EDIT_CURRENT_SPECIFICATIONS(state, data) {
    state.courseRequestSpecifications = [
      ...state.courseRequestSpecifications.map((item) =>
        item.id !== data.id ? item : { ...item, ...data }
      ),
    ];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
