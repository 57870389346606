/*=========================================================================================
  File Name: filters.js
  Description: Vuex store for filters
==========================================================================================*/
//imports

// initial state
const state = {
  courseFilters: {
    selectedDepartment: null,
    selectedColorFilter: [],
    selectedYear: "Alles",
    selectedMonth: "Alles",
    search: "",
    page: 1,
    filterFinished: false,
    switchExpand: false,
    switchColor: false,
    filtersActive: true,
    itemsPerPage: 25,
    sortDesc: [false],
    sortBy: ["courseName"],
  },
  optionFilters: {
    selectedDepartment: null,
    selectedTraining: null,
    selectedYear: "Alles",
    selectedMonth: "Alles",
    search: "",
    filtersActive: true,
  },
};

// getters
const getters = {
  getCourseFilters: (state) => {
    return state.courseFilters;
  },
  getOptionFilters: (state) => {
    return state.optionFilters;
  },
};

// actions
const actions = {
  //get all new registration
  setCourseFilters({ commit }, payload) {
    commit("SET_COURSE_FILTERS", payload);
  },
  setDefaultCourseFilters({ commit }, payload) {
    commit("SET_DEFAULT_COURSE_FILTERS", payload);
  },
  setOptionFilters({ commit }, payload) {
    commit("SET_OPTION_FILTERS", payload);
  },
  setOptionFilterDepartment({ commit }, payload) {
    commit("SET_OPTION_FILTER_DEPARTMENT", payload);
  },
  setDefaultOptionFilters({ commit }, payload) {
    commit("SET_DEFAULT_OPTION_FILTERS", payload);
  },
};

// mutations
const mutations = {
  SET_COURSE_FILTERS(state, data) {
    state.courseFilters = data;
  },
  SET_OPTION_FILTERS(state, data) {
    state.optionFilters = data;
  },
  SET_OPTION_FILTER_DEPARTMENT(state, data) {
    if (state.optionFilters.selectedDepartment == null)
      state.optionFilters.selectedDepartment = data;
  },
  SET_DEFAULT_COURSE_FILTERS(state) {
    state.courseFilters = {
      selectedDepartment: null,
      selectedColorFilter: [],
      selectedYear: "Alles",
      selectedMonth: "Alles",
      search: "",
      page: 1,
      filterFinished: false,
      switchExpand: false,
      switchColor: false,
      filtersActive: true,
      itemsPerPage: 25,
      sortDesc: [false],
      sortBy: ["courseName"],
    };
  },
  SET_DEFAULT_OPTION_FILTERS(state) {
    state.optionFilters = {
      selectedDepartment: null,
      selectedTraining: null,
      selectedYear: "Alles",
      selectedMonth: "Alles",
      search: "",
      filtersActive: true,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
