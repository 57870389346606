/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
    courseRequestCompanies: [],
};

// getters
const getters = {
    getCourseRequestCompanies: (state) => {
        return state.courseRequestCompanies;
    },
};

// actions
const actions = {
    //get all new registration
    fetchCourseRequestCompanies({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/CourseRequests/companies/" + payload.courseRequestId)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_COURSE_REQUEST_COMPANIES", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    addCoursesRequestCompany({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/CourseRequests/company", payload)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("ADD_COURSE_REQUEST_COMPANIES", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deleteCoursesRequestCompany({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("api/CourseRequests/company/" + payload.id)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("DELETE_COURSE_REQUEST_COMPANIES", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

// mutations
const mutations = {
    FETCH_COURSE_REQUEST_COMPANIES(state, data) {
        state.courseRequestCompanies = data;
    },
    ADD_COURSE_REQUEST_COMPANIES(state, data) {
        state.courseRequestCompanies.push(data);
    },
    DELETE_COURSE_REQUEST_COMPANIES(state, data) {
        let index = state.courseRequestCompanies.findIndex((element) => element.id == data.id);
        state.courseRequestCompanies.splice(index, 1)
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
