<template>
    <div>
        <transition name="slide" mode="out-in">
            <v-container>
                <v-tabs v-model="tabs" centered class="m-6">
                    <v-tab class="w-full" @click="navigate('Options')"
                        >Opties</v-tab
                    >
                    <v-tab class="w-full" @click="navigate('Courses')"
                        >Cursussen</v-tab
                    >
                    <v-tab class="w-full" @click="navigate('Teachers')"
                        >Docenten</v-tab
                    >
                </v-tabs>

                <v-tabs-items v-model="tabs">
                    <router-view />
                </v-tabs-items>

                <div class="mb-6">
                    Active picker: <code>{{ activePicker || "null" }}</code>
                </div>
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            label="Cursus datum"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        :active-picker.sync="activePicker"
                        :max="
                            new Date(
                                Date.now() -
                                    new Date().getTimezoneOffset() * 60000
                            )
                                .toISOString()
                                .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                    ></v-date-picker>
                </v-menu>
                <v-btn @click="goBack" depressed color="primary">
                    Ga terug
                </v-btn>
            </v-container>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            activePicker: null,
            date: null,
            menu: false,
            tabs: null,
            courseheaders: [
                {
                    text: "Id",
                    align: "start",
                    sortable: false,
                    value: "id",
                },
                { text: "Plaatsen", value: "places" },
                { text: "Opmerking", value: "remark" },
            ],
        };
    },
    mounted() {
        this.initialize();
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    computed: {
        ...mapGetters({
            courses: "courses/getCourses",
        }),
    },
    methods: {
        initialize() {
            this.$store
                .dispatch("courses/fetchCourses", false)
                .then(() => {})
                .catch(function () {});
        },
        save(date) {
            this.$refs.menu.save(date);
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

