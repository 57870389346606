/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  teachers: [],
  currentTeacher: {},
  currentTeacherPortal: {},
  trainingTeachers: [],
  teachersAndDepartments: [],
  teacherOptions: [],
  teachersBlockedDates: [],
  teacherAvailability: [],
  teacherRequests: [],
};

// getters
const getters = {
  getTeachers: (state) => {
    return state.teachers;
  },
  getCurrentTeacher: (state) => {
    return state.currentTeacher;
  },
  getCurrentTeacherPortal: (state) => {
    return state.currentTeacherPortal;
  },
  getTrainingTeachers: (state) => {
    return state.trainingTeachers;
  },
  getTeachersAndDepartments: (state) => {
    return state.teachersAndDepartments;
  },
  getTeacherOptions: (state) => {
    return state.teacherOptions;
  },
  getTeachersBlockedDates: (state) => {
    return state.teachersBlockedDates;
  },
  getTeacherAvailability: (state) => {
    return state.teacherAvailability;
  },
  getTeacherRequests: (state) => {
    return state.teacherRequests;
  },
};

// actions
const actions = {
  //get all new registration
  fetchTeachers({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Teachers/all")
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTeacherOptions({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Teachers/all/options")
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHER_OPTIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addTeacher({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Teachers/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("ADD_TEACHER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addTeacherDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Teachers/TeacherDates", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("ADD_TEACHER_DATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addMultipleTeacherDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Teachers/TeacherDatesMultiple", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("ADD_TEACHER_DATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteTeacher({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Teachers/" + payload.teacherId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("REMOVE_TEACHER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchTeachersByTraining({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/Teachers/training/" +
          payload.trainingId +
          "/" +
          payload.companyId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS_BY_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTeachersAndDepartments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Teachers/departments/" + payload.companyId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS_AND_DEPARTMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTeacher({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Teachers/" + payload.teacherId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("GET_TEACHER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTeachersHasBlockedDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Teachers/HasBlockedTeacherDates", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS_BLOCKED_DATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAvailableTeachers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Teachers/IsAvailableAtDates", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS_AVAILABILITY", res.data); //change
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addTeacherAvailabilityText({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Teachers/AddTeacherAvailabilityText", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS_AVAILABILITY", res.data); //change
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTeacherRequests({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Teachers/requestDates/" + payload.teacherId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS_REQUESTS", res.data); //change
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTeacherLink({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Teachers/teacherLink/" + payload.teacherId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS_PORTAL", res.data); //change
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  authenticateTeacher({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Login/teacher", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendQuestionFeedbackPortal({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Teachers/question", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_TEACHERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTeacherInformation({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Teachers/current")
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("GET_TEACHER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendTeacherPortalLogin({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Teachers/send/teacherportals")
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("GET_TEACHER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_TEACHERS_BY_TRAINING(state, data) {
    state.trainingTeachers = data;
  },
  FETCH_TEACHERS_AND_DEPARTMENTS(state, data) {
    state.teachersAndDepartments = data;
  },
  FETCH_TEACHERS(state, data) {
    state.teachers = data;
  },
  FETCH_TEACHERS_REQUESTS(state, data) {
    state.teacherRequests = data;
  },
  FETCH_TEACHERS_BLOCKED_DATES(state, data) {
    state.teachersBlockedDates = data;
  },
  FETCH_TEACHERS_AVAILABILITY(state, data) {
    state.teacherAvailability = data;
  },
  FETCH_TEACHER_OPTIONS(state, data) {
    state.teacherOptions = data;
  },
  ADD_TEACHER(state, data) {
    state.teachers = data;
  },
  ADD_TEACHER_DATES(state, data) {
    state.teachers.push(data);
  },
  REMOVE_TEACHER(state, data) {
    let index = state.teachers.findIndex((element) => element.id == data.id);
    state.teachers.splice(index, 1);
  },
  GET_TEACHER(state, data) {
    state.currentTeacher = data;
  },
  FETCH_TEACHERS_PORTAL(state, data) {
    state.currentTeacherPortal = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
