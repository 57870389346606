/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  planners: [],
  planner: null,
};

// getters
const getters = {
  getPlanners: (state) => {
    return state.planners;
  },
  getPlanner: (state) => {
    return state.planner;
  },
};

// actions
const actions = {
  fetchAllPlanners({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/users")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_ALL_PLANNERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCurrentPlanner({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/users/current")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_CURRENT_PLANNER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_ALL_PLANNERS(state, data) {
    state.planners = data;
  },
  FETCH_CURRENT_PLANNER(state, data) {
    state.planner = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
