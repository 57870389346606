import driverAuthBearer from "./authBearer";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";
import driverOAuth2Google from "@websanova/vue-auth/dist/drivers/oauth2/google.esm.js";
import driverOAuth2Facebook from "@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js";

driverOAuth2Google.params.client_id =
  "547886745924-4vrbhl09fr3t771drtupacct6f788566.apps.googleusercontent.com";
driverOAuth2Facebook.params.client_id = "196729390739201";

// Auth base configuration some of this options
// can be override in method calls
const DRIVERS = {
  auth: driverAuthBearer,
  http: driverHttpAxios,
  router: driverRouterVueRouter,
  oauth2: {
    google: driverOAuth2Google,
    facebook: driverOAuth2Facebook,
  },
};

const OPTIONS = {
  rolesKey: "roles",
  notFoundRedirect: { name: "Options" },
  tokenDefaultKey: "token",
  refreshTokenName: "refreshToken",
  tokenStore: ["localStorage"],
  refreshData: {
    enabled: true,
    url: "/api/token/refresh",
    interval: 19,
    method: "POST",
  },
  fetchData: { url: "/api/Users/current", enabled: true },
  authRedirect: "/authentication/login",
  parseUserData(data) {
    return data || {};
  },
  loginData: {
    url: "/api/login",
    method: "POST",
    redirect: "/home/opties",
    fetchUser: true,
  },
  logoutData: {
    url: "/api/logout",
    method: "POST",
    redirect: "/authentication/login",
    fetchUser: false,
  },
};

export { DRIVERS, OPTIONS };
