<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{
            multiple
              ? "Welke switches zijn van toepassing op deze cursussen?"
              : "Welke switch is van toepassing op deze cursus?"
          }}
        </v-card-title>
        <v-card-text>
          {{
            multiple
              ? "Voeg de switch toe die je wilt toevoegen aan deze cursussen."
              : "Voeg de switch toe die je wilt toevoegen aan deze cursus."
          }}
        </v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" sm="6">
              <v-subheader v-text="'Voeg hier je switches toe'"></v-subheader>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="courseswitches"
                :items="CourseSwitches"
                item-text="name"
                :menu-props="{ maxHeight: '400' }"
                label="Select"
                multiple
                hint="Klik op switches die je wilt toevoegen"
                persistent-hint
                no-data-text="Geen switches gevonden"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="courseSwitchToggle">
            Annuleren
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="multiple ? AddMultipleCourseSwitches() : AddCourseSwitch()"
          >
            Toevoegen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    active: Boolean,
    multiple: {
      type: Boolean,
      default: false,
    },
    courseIds: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      dialog: true,
      courseswitches: [],
    };
  },

  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters({
      switches: "courses/getSwitches",
      switchesByCourse: "courses/getSwitchesByCourse",
    }),
    courseId() {
      return this.$route.params.id;
    },

    CourseSwitches() {
      const array = this.switchesByCourse.map((x) => x.switchId);

      let filteredSwitches = this.switches.filter(
        (val) => !array.includes(val.id)
      );

      return filteredSwitches;
    },
  },
  methods: {
    initialize() {
      this.fetchAllSwitches();
    },
    courseSwitchToggle() {
      this.$emit("courseSwitchToggle");
    },
    fetchAllSwitches() {
      this.$store
        .dispatch("courses/fetchSwitches")
        .then(() => {
          this.CourseSwitches();
        })
        .catch(function () {});
    },
    AddCourseSwitch() {
      let app = this;
      var switcharray = [];
      app.courseswitches.forEach((element) => {
        const obj = app.switches.find((element2) => element == element2.name);
        if (obj) {
          switcharray.push(obj);
        }
      });

      var switcharrayv2 = [];
      switcharray.forEach((item) => {
        var obj = {
          CourseId: app.courseId,
          Switch: item,
          SwitchId: item.id,
          Value: "",
        };
        switcharrayv2.push(obj);
      });

      let payload = switcharrayv2;

      app.$store.dispatch("toggleLoader");
      this.$store
        .dispatch("courses/addCourseSwitch", payload)
        .then(() => {
          app.$store.dispatch("toggleLoader");
          app.$store.dispatch("addMessage", {
            message: "Toevoegen van switch is gelukt!",
            bottom: true,
            top: false,
            right: true,
            color: "green",
            transition: "fab-transition",
            timeout: 3000,
          });

          this.courseSwitchToggle();
        })
        .catch(function (error) {
          if (error.response.status == 400) {
            app.$store.dispatch("toggleLoader");
            app.$store.dispatch("addMessage", {
              message: "Deze switch bestaat al in deze cursus",
              bottom: true,
              top: false,
              right: true,
              color: "warning",
              transition: "fab-transition",
              timeout: 3000,
            });
          }
        });
    },
    AddMultipleCourseSwitches() {
      let app = this;
      var switcharray = [];
      app.courseswitches.forEach((element) => {
        const obj = app.switches.find((element2) => element == element2.name);
        if (obj) {
          switcharray.push(obj);
        }
      });

      var switcharrayv2 = [];
      switcharray.forEach((item) => {
        var obj = {
          CourseIds: app.courseIds,
          Switch: item,
          SwitchId: item.id,
          Value: "",
        };
        switcharrayv2.push(obj);
      });

      let payload = switcharrayv2;

      app.$store.dispatch("toggleLoader");
      this.$store
        .dispatch("courses/addMultipleCourseSwitches", payload)
        .then(() => {
          app.$store.dispatch("toggleLoader");
          app.$store.dispatch("addMessage", {
            message: "Toevoegen van switch is gelukt!",
            bottom: true,
            top: false,
            right: true,
            color: "green",
            transition: "fab-transition",
            timeout: 3000,
          });

          this.courseSwitchToggle();
        })
        .catch(function (error) {
          if (error.response.status == 400) {
            app.$store.dispatch("toggleLoader");
            app.$store.dispatch("addMessage", {
              message: "Deze switch bestaat al in deze cursus",
              bottom: true,
              top: false,
              right: true,
              color: "warning",
              transition: "fab-transition",
              timeout: 3000,
            });
          }
        });
    },
  },
};
</script>
