<template>
  <!-- ----------------------------------------------------------------------------- -->
  <!-- GlobalLoader -->
  <!-- ----------------------------------------------------------------------------- -->
  <div>
    <v-overlay :value="loader" class="upper">
      <!-- <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular> -->
      <v-img
        :style="'background-color: white'"
        :src="require('@/assets/loader/Logo Loading Full Loop 150x150.gif')"
        max-width="150px"
        contain
        class="rounded-xl"
      />
    </v-overlay>
  </div>
</template>

<style scoped>
.upper {
  z-index: 999 !important;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GlobalLoader",

  data: () => ({
    overlay: false,
  }),
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
  computed: {
    ...mapGetters({
      loader: "getLoaderState",
    }),
  },
};
</script>
