<template>
    <v-row
        :class="[
            bottomMargin ? 'page-breadcrumb' : 'page-breadcrumb-no-margin',
        ]"
    >
        <v-col cols="12" sm="12" class="pt-6">
            <v-card class="px-2 custom-shaodow">
                <v-toolbar dense flat class="transparent pa-0" height="40px">
                    <v-toolbar-title
                        :class="{ 'pb-2': $vuetify.breakpoint.mdAndDown }"
                        class="pt-3"
                    >
                        <span class="text--primary">{{ title }}</span>
                    </v-toolbar-title>
                    <v-toolbar-title
                        class="toobar-extension transparent pb-3"
                        slot="extension"
                    >
                        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                            <template v-slot:divider v-if="breadcrumbs">
                                <v-icon>mdi-chevron-right</v-icon>
                            </template>
                        </v-breadcrumbs>

                        <slot></slot>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "BaseBreadcrumb",
    props: {
        title: String,
        breadcrumbs: Array,
        icon: String,
        bottomMargin: { Type: Boolean, Default: true },
    },
    data: () => ({}),
};
</script>
<style lang="scss">
.page-breadcrumb {
    margin: -24px -25px 27px -25px;
}
.page-breadcrumb-no-margin {
    margin: -24px -25px 0px -25px;
}
</style>