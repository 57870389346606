/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  companies: [],
};

// getters
const getters = {
  getCompanies: (state) => {
    return state.companies;
  },
};

// actions
const actions = {
  //get all new registration
  fetchCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Companies")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_COMPANIES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_COMPANIES(state, data) {
    state.companies = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
