/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  courseRequestEmails: [],
};

// getters
const getters = {
  getCourseRequestEmails: (state) => {
    return state.courseRequestEmails;
  },
};

// actions
const actions = {
  fetchEmailsByCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/emails/request/" + payload.courseRequestId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_EMAILS_BY_COURSE_REQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resendEmail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "api/emails/request/" + payload.id + "/" + payload.courseRequestId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("UPDATE_EMAIL_BY_COURSE_REQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_EMAILS_BY_COURSE_REQUEST(state, data) {
    state.courseRequestEmails = data;
  },
  UPDATE_EMAIL_BY_COURSE_REQUEST(state, data) {
    state.courseRequestEmails = [
      ...state.courseRequestEmails.map((item) =>
        item.id !== data.id ? item : { ...item, ...data }
      ),
    ];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
