/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
    departments: [],
};

// getters
const getters = {
    getDepartments: (state) => {
        return state.departments;
    },
};

// actions
const actions = {
    fetchDepartments({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Departments/")
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_DEPARTMENTS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

// mutations
const mutations = {
    FETCH_DEPARTMENTS(state, data) {
        state.departments = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
