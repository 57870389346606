<template>
  <!-- ----------------------------------------------------------------------------- -->
  <!-- Snackbars -->
  <!-- ----------------------------------------------------------------------------- -->
  <div>
    <v-snackbars :objects.sync="objects" top right>
      <template v-slot:action="{ close }">
        <v-btn text @click="close()">Sluiten</v-btn>
      </template>
    </v-snackbars>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VSnackbars from "v-snackbars";

export default {
  name: "SnackBars",

  data: () => ({
    overlay: false,
    objects: [],
  }),
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
    messages(val) {
      this.objects.push(val[val.length - 1]);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      messages: "getMessages",
    }),
  },
  components: {
    "v-snackbars": VSnackbars,
  },
};
</script>