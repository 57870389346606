/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  components: [],
};

// getters
const getters = {
  getComponents: (state) => {
    return state.components;
  },
};

// actions
const actions = {
  //get all new registration
  getComponentsByCourseRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Components/" + payload.courseRequestId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COMPONENTS", res.data);
        })
        .catch((err) => {
          commit("FETCH_COMPONENTS", []);
          reject(err);
        });
    });
  },
  postCourseRequestComponents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Components/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("MUTATE_NOTHING");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_COMPONENTS(state, data) {
    state.components = data;
  },
  MUTATE_NOTHING(state) {
    state.components = [...state.components];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
