<template>
  <v-card class="mb-7" :flat="flat">
    <v-toolbar flat v-if="showToolbar">
      <v-toolbar-title>
        {{ heading }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <div :class="!noPadding ? 'pa-4' : ''">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",

  props: {
    heading: {
      type: String,
      default: "",
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),

  methods: {},
};
</script>
