import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import "./assets/css/tailwind.css";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";
import moment from "moment";
import "./plugins/base";
import VueConfetti from "vue-confetti";
import OneSignalVue from "onesignal-vue";
import VueApexCharts from "vue-apexcharts";

function OneSignalInit() {
  window.plugins.OneSignal.setAppId("6fb5bfbf-3262-4055-9b22-7460a818ed69");
  window.plugins.OneSignal.setNotificationOpenedHandler(function (jsonData) {
    console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
  });

  window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function (
    accepted
  ) {
    console.log("User accepted notifications: " + accepted);
  });
}

// local moment
moment.locale("nl");

// authentication
import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js";
import { DRIVERS, OPTIONS } from "./auth/authconfig";

// Filters
import "./filters/filters";

// validation Configurations
import dictionary from "./config/validationConfig";
import "./config/configuration";
import { baseURLProd, baseURLLocal } from "./config/configuration";

// Vee-Validate
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import nl from "vee-validate/dist/locale/nl.json";

// Register Vue Plugins
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(VueConfetti);
Vue.use(OneSignalVue);
Vue.use(VueApexCharts);

Vue.config.productionTip = false;

// Axios Configuration
if (process.env.NODE_ENV === "production") {
  Vue.axios.defaults.baseURL = baseURLProd;
} else {
  Vue.axios.defaults.baseURL = baseURLLocal;
}

Vue.axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
Vue.axios.defaults.headers.post["Accept"] = "application/json";
Vue.axios.defaults.headers.common["Authorization"] =
  `Bearer ` + localStorage[auth.tokenDefaultName];

// Vee-Validate Configuration
for (const rule in rules) {
  extend(rule, rules[rule]);
}

localize(dictionary);
localize("nl", nl);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("apexchart", VueApexCharts);

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: router,
  },
  drivers: DRIVERS,
  options: OPTIONS,
});

// OneSignal Initialization in Production
if (process.env.NODE_ENV === "production") {
  new Vue({
    store,
    vuetify,
    router,
    render: (h) => h(App),
    beforeMount() {
      this.$OneSignal.init({
        appId: "6fb5bfbf-3262-4055-9b22-7460a818ed69",
        allowLocalhostAsSecureOrigin: true,
      });
    },
  }).$mount("#app");

  // Cordova OneSignal logic
  document.addEventListener("deviceready", OneSignalInit, false);
} else {
  new Vue({
    store,
    vuetify,
    router,
    render: (h) => h(App),
  }).$mount("#app");
}
