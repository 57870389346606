<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
    <global-loader />
    <snack-bars />
  </v-app>
</template>

<script>
import GlobalLoader from "./components/commonComponents/GlobalLoader.vue";
import SnackBars from "./components/commonComponents/SnackBars.vue";
export default {
  name: "App",

  data: () => ({
    //d
  }),
  components: {
    GlobalLoader,
    SnackBars,
  },
  mounted() {
    //For all base inits like fetching environment
    this.syncEnvironment();
    this.fetchServerEnvironment();
    this.$auth.fetch();
  },
  methods: {
    syncEnvironment() {
      this.$store.dispatch("tools/setCurrentEnvironment");
    },
    fetchServerEnvironment() {
      this.$store.dispatch("tools/fetchServerEnvironment");
    },
  },
};
</script>
