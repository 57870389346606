<template>
  <div>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <div>
      <v-row>
        <v-col cols="12" sm="12" class="pa-0 pa-md-1">
          <!-- <v-tabs v-model="tabs" centered class="m-6">
        <v-tab class="w-full" @click="navigate('Options')">Opties</v-tab>
        <v-tab class="w-full" @click="navigate('Courses')">Cursussen</v-tab>
        <v-tab class="w-full" @click="navigate('Teachers')">Docenten</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <router-view />
      </v-tabs-items> -->
          <BaseCard heading="Archief">
            <v-row>
              <v-col>
                <v-btn rounded color="primary" outlined @click="goBack">
                  <v-icon left> mdi-arrow-left </v-icon>
                  Terug
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="deleted"
                  :search="search"
                  :custom-filter="filterText"
                  loading-text="De data wordt geladen.."
                  show-group-by
                  group-by="trainingName"
                  item-key="name"
                  sort-by="name"
                  :footer-props="{
                    'items-per-page-text': 'Aantal per pagina',
                    'items-per-page-options': [10, 25, 50, 100, -1],
                  }"
                  no-results-text="Geen resultaten gevonden"
                  :items-per-page="50"
                >
                  <template v-slot:item.name="{ item }">
                    <div class="d-flex align-center">
                      <div v-if="$vuetify.breakpoint.name != 'xs'">
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.companyName="{ item }">
                    <div class="d-flex align-center">
                      <div v-if="$vuetify.breakpoint.name != 'xs'">
                        <span>{{ item.companyName }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.city="{ item }">
                    <div class="d-flex align-center">
                      <div v-if="$vuetify.breakpoint.name != 'xs'">
                        <span>{{ item.city }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.amount="{ item }">
                    <div class="d-flex align-center">
                      <div v-if="$vuetify.breakpoint.name != 'xs'">
                        <span>{{ item.amount }}</span>
                      </div>
                    </div>
                    <!-- </div> -->
                  </template>
                  <template v-slot:item.requestDate="{ item }">
                    <span class="subtitle-2 font-weight-regular mr-2">{{
                      item.requestDate | regularDate
                    }}</span>
                    <v-chip
                      v-if="$vuetify.breakpoint.name != 'xs'"
                      color="primary"
                      label
                      >{{ item.requestDate | time }}</v-chip
                    >
                  </template>
                  <template v-slot:item.birthday="{ item }">
                    <span class="subtitle-2 d-block font-weight-regular">{{
                      item.birthday | regularDate
                    }}</span>
                  </template>
                  <template v-slot:item.dateValidTeacher="{ item }">
                    <v-avatar
                      v-if="$vuetify.breakpoint.name != 'xs'"
                      :color="!item.dateValidTeacher ? 'error' : 'success'"
                      size="30"
                    >
                      <v-icon color="white">{{
                        !item.dateValidTeacher ? "mdi-close" : "mdi-check"
                      }}</v-icon>
                    </v-avatar>
                    <span v-else>
                      <v-icon
                        :color="!item.dateValidTeacher ? 'error' : 'success'"
                        >{{
                          !item.dateValidTeacher ? "mdi-close" : "mdi-check"
                        }}</v-icon
                      >
                    </span>
                  </template>
                  <template v-slot:item.dateValidCompany="{ item }">
                    <v-avatar
                      v-if="$vuetify.breakpoint.name != 'xs'"
                      :color="!item.dateValidCompany ? 'error' : 'success'"
                      size="30"
                    >
                      <v-icon color="white">{{
                        !item.dateValidCompany ? "mdi-close" : "mdi-check"
                      }}</v-icon>
                    </v-avatar>
                    <span v-else>
                      <v-icon
                        :color="!item.dateValidCompany ? 'error' : 'success'"
                        >{{
                          !item.dateValidCompany ? "mdi-close" : "mdi-check"
                        }}</v-icon
                      >
                    </span>
                  </template>
                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize"> Reset </v-btn>
                  </template>
                  <template
                    v-slot:group.header="{
                      group,
                      headers,
                      toggle,
                      isOpen,
                      remove,
                      groupBy,
                    }"
                  >
                    <td :colspan="headers.length">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="toggle"
                            x-small
                            icon
                            :ref="group"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon v-if="isOpen">mdi-plus</v-icon>
                            <v-icon v-else>mdi-minus</v-icon>
                          </v-btn>
                        </template>
                        <span
                          >Klik om te
                          {{ isOpen ? "minimaliseren" : "maximaliseren" }}</span
                        >
                      </v-tooltip>
                      <span class="ml-3 mr-1"
                        >{{ getHeaderByValue(groupBy[0]).text }}:</span
                      >
                      <span class="mr-3 font-weight-medium">{{ group }}</span>
                      <!-- <v-spacer></v-spacer> -->
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="remove"
                            x-small
                            icon
                            :ref="group"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-close-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Klik om de groepering stop te zetten.</span>
                      </v-tooltip>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </BaseCard>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      value: "",
      page: {
        title: "Archief inzage",
      },
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          to: "/home/opties",
        },
        {
          text: "Tabs",
          disabled: false,
          to: "/home/opties",
        },
        {
          text: "Opties",
          disabled: false,
          to: "/home/opties",
        },
        {
          text: "Archief verwijderd",
          disabled: true,
        },
      ],
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters({
      deleted: "courseRequests/getDeletedCourseRequests",
    }),

    headers() {
      return [
        {
          text: "Naam",
          align: "start",
          sortable: false,
          value: "name",
          groupable: false,
        },
        {
          text: "Bedrijf",
          value: "companyName",
          groupable: false,
        },
        {
          text: "Plaats",
          value: "city",
          groupable: false,
        },
        {
          text: "Opleiding",
          value: "trainingName",
          groupable: true,
        },
        {
          text: "Aantal",
          value: "amount",
          groupable: false,
        },
        {
          text: "datum docent",
          value: "dateValidTeacher",
          groupable: false,
        },
        {
          text: "Datum klant",
          value: "dateValidCompany",
          groupable: false,
        },
        {
          text: "Voorkeursdatum",
          value: "requestDate",
          groupable: false,
        },
        { text: "Acties", value: "actions", groupable: false },
      ];
    },
  },
  methods: {
    initialize() {
      this.$store
        .dispatch("courseRequests/fetchDeletedCoursesRequests")
        .then(() => {})
        .catch(function () {});
    },
    goBack() {
      this.$router.go(-1);
    },
    getHeaderByValue(value) {
      return this.headers.find((obj) => {
        return obj.value === value;
      });
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toString().toLocaleLowerCase()) !== -1
      );
    },
  },
};
</script>

