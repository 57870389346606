/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  fetchedReports: [],
};

// getters
const getters = {
  GetFetchedReports: (state) => {
    return state.fetchedReports;
  },
};

// actions
const actions = {
  //get all new registration
  fetchOptionsReport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/report/options", payload, { responseType: "blob" })
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_REPORTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_REPORTS(state, data) {
    state.fetchedReports = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
