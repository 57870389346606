/*=========================================================================================
  File Name: validationConfig.js
  Description: validation configuration
==========================================================================================*/

import { extend, setInteractionMode } from "vee-validate";
import moment from "moment";

//validation messages
const dictionary = {
  nl: {
    messages: {
      date_format_dutch: (val) =>
        `${val} is niet een geldige datum, houd dd-mm-jjjj aan.`,
      date_max_n_days_away: (val, { maxDays }) =>
        `${val} moet binnen ${maxDays} dagen vanaf vandaag zijn.`,
      time_format: (val) => `${val} is niet een geldige tijd.`,
      is_past_date: (val) => `${val} moet voor vandaag zijn.`,
    },
  },
};

setInteractionMode("lazy");

extend("date_format_dutch", (value) => {
  // Check valid using regex for both formats
  const regx = new RegExp(
    "^([1-2][0-9]|3[0-1]|0?[1-9])[-]([1][0-2]|0?[1-9])[-]([0-9]{4})$"
  );

  // Check valid date using moment for both formats
  const dateFormats = ["DD-MM-YYYY", "D-M-YYYY"];
  const isValidFormat = dateFormats.some((format) =>
    moment(value, format, true).isValid()
  );

  return regx.exec(value) && isValidFormat;
});

extend("date_max_n_days_away", {
  validate(value, { maxDays }) {
    const dateFormats = ["DD-MM-YYYY", "D-M-YYYY"];
    const date = dateFormats.reduce((validDate, format) => {
      return validDate || moment(value, format, true);
    }, null);

    if (!date || !date.isValid()) {
      return false;
    }

    const today = moment();
    const maxDate = today.add(maxDays, "days");
    return date.isBefore(maxDate);
  },
  params: ["maxDays"],
});

extend("is_past_date", (value) => {
  // Check valid date & make sure date is before current date
  var dateFormats = ["DD-MM-YYYY", "D-M-YYYY", "DD-M-YYYY", "D-MM-YYYY"];
  var date;
  var isValidDate = false;

  // Iterate over date formats until a valid date is found
  for (let i = 0; i < dateFormats.length; i++) {
    date = moment(value, dateFormats[i], true);
    if (date.isValid()) {
      isValidDate = true;
      break;
    }
  }

  if (!isValidDate) return false; // Return false if none of the formats are valid

  var dateIsBeforeNow = date.isBefore(moment());
  return dateIsBeforeNow;
});

extend("time_format", (value) => {
  const regx = new RegExp("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$");
  return regx.exec(value);
});

export default dictionary;
