import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import viewCourse from "./components/course/viewCourse";
import viewOption from "./components/modals/viewOption";
import viewDeleted from "./components/modals/viewDeleted";
//import VueMouseflow from "vue-mouseflow";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/full",
      name: "Full",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          path: "datum-selecteren/:token",
          name: "DateSelect",
          props: true,
          component: () => import("@/components/date-select/DateSelect"),
          meta: { transitionName: "slide" },
        },
        {
          path: "datum-kiezen/:token",
          name: "ChooseDate",
          props: true,
          component: () => import("@/components/choose-date/ChooseDate"),
          meta: { transitionName: "slide" },
        },
      ],
    },
    {
      path: "/teacher",
      name: "teacherHome",
      component: () => import("@/layouts/teacher-layout/TeacherLayout"),
      meta: {
        auth: {
          roles: ["Teacher"],
          // roles: ["Planner", "Teacher", "Sales"],
          // redirect: "/home/opties",
          forbiddenRedirect: { name: "TeacherLogin" },
        },
      },
      children: [
        {
          path: "agenda",
          name: "TeacherPortalView",
          props: true,
          component: () =>
            import("@/components/teacher-portal/TeacherPortalCalendarView"),
          meta: {
            transitionName: "slide",
            auth: {
              roles: ["Teacher"],
              // roles: ["Planner", "Teacher", "Sales"],
              // redirect: "/home/opties",
              forbiddenRedirect: { name: "TeacherLogin" },
            },
          },
        },
        {
          path: "aanvragen",
          name: "TeacherPortalRequestsView",
          props: true,
          component: () =>
            import("@/components/teacher-portal/TeacherPortalRequestsView"),
          meta: { transitionName: "slide" },
        },
        {
          path: "handleiding",
          name: "TeacherPortalManualView",
          redirect: "/teacher/handleiding/algemeen",
          props: true,
          component: () =>
            import("@/components/teacher-portal/TeacherPortalManualView"),
          meta: { transitionName: "slide" },
          children: [
            {
              path: "agenda",
              name: "TeacherManualCalendarView",
              props: true,
              component: () =>
                import(
                  "@/components/teacher-portal/manual/TeacherManualCalendarView"
                ),
              meta: { transitionName: "slide" },
            },
            {
              path: "algemeen",
              name: "TeacherManualDefaultView",
              props: true,
              component: () =>
                import(
                  "@/components/teacher-portal/manual/TeacherManualDefaultView"
                ),
              meta: { transitionName: "slide" },
            },
            {
              path: "aanvragen",
              name: "TeacherManualRequestsView",
              props: true,
              component: () =>
                import(
                  "@/components/teacher-portal/manual/TeacherManualRequestsView"
                ),
              meta: { transitionName: "slide" },
            },
            {
              path: "synchronisatie",
              name: "TeacherManualSyncView",
              props: true,
              component: () =>
                import(
                  "@/components/teacher-portal/manual/TeacherManualSyncView"
                ),
              meta: { transitionName: "slide" },
            },
          ],
        },
        {
          path: "invullen/:token/:type",
          name: "TeacherPortalFillView",
          props: true,
          component: () =>
            import("@/components/teacher-portal/TeacherPortalFillView"),
          meta: { transitionName: "slide" },
        },
      ],
    },
    {
      path: "/",
      name: "home",
      redirect: "/home",
      component: () => import("@/layouts/base-layout/Layout"),
      meta: {
        auth: {
          roles: ["Planner", "Administrator", "Sales"],
          // redirect: "/home/opties",
          forbiddenRedirect: { name: "Login" },
        },
      },
      children: [
        {
          path: "course",
          name: "course",
          component: viewCourse,
          meta: { transitionName: "slide" },
        },
        {
          path: "deleted",
          name: "deleted",
          component: viewDeleted,
          meta: { transitionName: "slide" },
        },
        {
          path: "option",
          name: "option",
          component: viewOption,
          meta: { transitionName: "slide" },
        },
        {
          path: "/teacher/:id",
          name: "TeacherView",
          props: true,
          component: () => import("@/components/teacher/TeacherView"),
          meta: { transitionName: "slide" },
        },
        {
          path: "/multiple-teacher",
          name: "MultipleTeacherView",
          props: true,
          component: () => import("@/components/teacher/MultipleTeacherView"),
          meta: { transitionName: "slide" },
        },
        {
          path: "/devtools/",
          name: "DevelopmentTools",
          meta: {
            auth: {
              roles: ["Administrator"],
              // redirect: "/home/opties",
              forbiddenRedirect: { name: "home" },
            },
          },
          props: true,
          component: () =>
            import("@/components/development-tools/DevelopmentTools"),
        },
        {
          path: "/instellingen/",
          name: "Settings",
          meta: {
            auth: {
              roles: ["Administrator"],
              // redirect: "/home/opties",
              forbiddenRedirect: { name: "home" },
            },
          },
          props: true,
          component: () => import("@/components/settings/UserSettings"),
        },
        {
          path: "/option/:id",
          name: "OptionView",
          redirect: "/option/:id/overzicht",
          props: true,
          component: () => import("@/components/option/OptionView"),
          meta: { transitionName: "slide" },
          children: [
            {
              name: "OptionDates",
              path: "datums",
              component: () => import("@/components/option/option-tabs/Dates"),
            },
            {
              name: "OptionOverview",
              path: "overzicht",
              component: () =>
                import("@/components/option/option-tabs/Overview"),
            },
            {
              name: "OptionTimeline",
              path: "tijdlijn",
              component: () =>
                import("@/components/option/option-tabs/Timeline"),
            },
            {
              name: "OptionCompanies",
              path: "bedrijven",
              component: () =>
                import("@/components/option/option-tabs/Companies"),
            },
            {
              name: "OptionEmails",
              path: "emails",
              component: () => import("@/components/option/option-tabs/Emails"),
            },
            {
              name: "OptionSpecifications",
              path: "specificaties",
              component: () =>
                import("@/components/option/option-tabs/Specifications"),
            },
          ],
        },
        {
          path: "/course/:id",
          name: "viewCourse",
          props: true,
          component: () => import("@/components/course/viewCourse"),
          meta: { transitionName: "slide" },
        },
        {
          path: "/multiple-courses",
          name: "MultipleCoursesView",
          props: true,
          component: () => import("@/components/course/MultipleCourseView"),
          meta: { transitionName: "slide" },
        },
        //Coachee specific routes
        {
          path: "/home",
          name: "main",
          redirect: "/home/opties",
          component: () => import("@/Homepage"),
          children: [
            {
              name: "Options",
              path: "opties",
              component: () =>
                import("@/components/homepage/homepage-tabs/Options"),
            },
            {
              name: "Courses",
              path: "cursussen",
              props: true,
              component: () =>
                import("@/components/homepage/homepage-tabs/Courses"),
            },
            {
              name: "Teachers",
              path: "docenten",
              redirect: "docenten/overzicht",
              component: () =>
                import("@/components/homepage/homepage-tabs/Teachers"),
              children: [
                {
                  name: "DefaultTeachers",
                  path: "overzicht",
                  component: () =>
                    import(
                      "@/components/teacher/teacher-overview/DefaultTeachersView"
                    ),
                },
                {
                  name: "DefaultOptions",
                  path: "docentopties",
                  component: () =>
                    import(
                      "@/components/teacher/teacher-overview/OptionTeachersView"
                    ),
                },
                {
                  name: "DefaultTeacherSearch",
                  path: "docentzoeken",
                  component: () =>
                    import(
                      "@/components/teacher/teacher-overview/TeacherSearchView"
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "login",
          component: () => import("@/components/authentication/Login"),
          meta: {
            auth: false,
            forbiddenRedirect: { name: "TeacherLogout" },
          },
        },
        {
          name: "TeacherLogin",
          path: "teacherlogin/:id",
          props: true,
          component: () =>
            import("@/components/authentication/teacher/TeacherLogin"),
          meta: {
            auth: false,
            forbiddenRedirect: { name: "TeacherLogout" },
          },
        },
        {
          name: "TeacherLogout",
          path: "uitgelogd",
          props: true,
          component: () =>
            import("@/components/authentication/teacher/TeacherLogout"),
          meta: {
            auth: false,
          },
        },
      ],
    },
  ],
});

import NProgress from "nprogress";

router.beforeEach((to, from, next) => {
  if (Vue.auth.user() != null) {
    let roles = Vue.auth.user().roles;
    if (roles.includes("Teacher") && to.name == "Options") {
      router.push({ name: "TeacherPortalView" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  //console.log("navigate to", to.name);
  if (to) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
  //VueMouseflow.logRouteChange(to, { includeRouteParams: false });
});

export default router;
