import Vue from "vue";
import Vuex from "vuex";

//modules
import courses from "./modules/courses.js";
import companies from "./modules/companies.js";
import teachers from "./modules/teachers.js";
import coursedates from "./modules/coursedates.js";
import components from "./modules/components.js";
import courseRequestCompanies from "./modules/courseRequestCompanies.js";
import courseRequests from "./modules/courseRequests.js";
import departments from "./modules/departments.js";
import specifications from "./modules/specifications";
import locations from "./modules/locations";
import emails from "./modules/emails";
import filters from "./modules/filters";
import theme from "./modules/theme";
import faker from "./modules/faker";
import planners from "./modules/planners";
import reports from "./modules/reports";
import tools from "./modules/tools";

Vue.use(Vuex);
const state = {
  count: 0,
  loaderEnabled: false,
  messages: [],
};
const mutations = {
  TOGGLE_LOADER(state) {
    state.loaderEnabled = !state.loaderEnabled;
  },
  ADD_MESSAGE(state, data) {
    state.messages = [];
    state.messages.unshift(data);
  },
};

const actions = {
  toggleLoader({ commit }) {
    commit("TOGGLE_LOADER");
  },
  addMessage({ commit }, payload) {
    commit("ADD_MESSAGE", payload);
  },
};

const getters = {
  getLoaderState: (state) => {
    return state.loaderEnabled;
  },
  getMessages: (state) => {
    return state.messages;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    courses: courses,
    companies: companies,
    teachers: teachers,
    coursedates: coursedates,
    components: components,
    courseRequestCompanies: courseRequestCompanies,
    courseRequests: courseRequests,
    departments: departments,
    specifications: specifications,
    locations: locations,
    emails: emails,
    filters: filters,
    theme: theme,
    faker: faker,
    planners: planners,
    reports: reports,
    tools: tools,
  },
});
