/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
    locations: [],
};

// getters
const getters = {
    getLocations: (state) => {
        return state.locations;
    },
};

// actions
const actions = {
    //get all new registration
    fetchLocations({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/locations")
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_LOCATIONS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

// mutations
const mutations = {
    FETCH_LOCATIONS(state, data) {
        state.locations = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
