/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  generatedComponents: [],
};

// getters
const getters = {
  GetGeneratedComponents: (state) => {
    return state.generatedComponents;
  },
};

// actions
const actions = {
  //get all new registration
  fetchGeneratedComponents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/faker/components", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_GENERATED_COMPONENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchGeneratedComponentsTeachers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/faker/teachers", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_GENERATED_COMPONENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchGeneratedComponentSingle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/faker/component/single", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_GENERATED_COMPONENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchGeneratedComponentTeacherSingle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/faker/teacher/single", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_GENERATED_COMPONENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_GENERATED_COMPONENTS(state, data) {
    state.generatedComponents = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
